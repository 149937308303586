import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasTrainingDays, publicName } from "../utils/config";
import {
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
} from "@mui/material";
import Person from "@mui/icons-material/Person";
import Delete from "@mui/icons-material/Delete";
import { checkNicknameExists, registerUser } from "../utils/user";
import { signin, unloadProfile } from "../actions/userActions";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import {
  loadCategories,
  loadParams,
  setDiscipline,
} from "../actions/paramActions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BirthPlace from "../components/BirthPlace";

export default function RegisterScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [modUser, setModUser] = useState({
    surname: "",
    name: "",
    nickname: "",
    gender: "M",
    email: "",
    phonenr: "",
    homenr: "",
    //birthdate: "",
    birthdateD: new Date(),
    birthloc: "",
    birthprov: "",
    citizenship: "",
    city: "",
    prov: "",
    address: "",
    postcode: "",
    trainings: 0,
    location: "",
    group: "",
    parents: [
      { surname: "", name: "", link: "", email: "", cf: "", phonenr: "" },
    ],
  });

  const [registerMode, setRegisterMode] = useState({
    discipline: null,
    discName: null,
    category: null,
    jr: false,
    locked: false,
    filter: true,
  });

  const [nickNameError, setNickNameError] = useState(false);
  const [errorPassword, setErrorPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const [lockParent, setLockParents] = useState(false);

  const paramList = useSelector((state) => state.paramList);
  const { categories, parameters, locations, groupNames, disciplines } =
    paramList;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useParams();

  useEffect(() => {
    dispatch(unloadProfile());
    if (!categories?.length) {
      dispatch(loadCategories());
    } else {
      if (disciplines?.length && registerMode.discipline === null) {
        const discfilter = disciplines.find(
          (disc) => disc.id.toLowerCase() === params.discipline.toLowerCase()
        );
        if (discfilter) {
          const cat = categories.find(
            (c) => c.isinternal === "0" && c.discipline === discfilter.id
          );

          setRegisterMode({
            discipline: discfilter.id,
            discName: discfilter.name,
            disc: discfilter,
            filter: true,
            locked: false,
            jr: cat ? cat.isjr > 0 : false,
            category: cat ? cat.key : null,
          });

          dispatch(setDiscipline(discfilter.id));
        } else {
          const index = disciplines.findIndex(
            (disc) => disc.id === categories[0].discipline
          );
          const discName =
            index >= 0 ? disciplines[index].name : disciplines[0].name;

          setRegisterMode({
            locked: false,
            filter: false,
            discipline: categories[0].discipline,
            discName: discName,
            category: categories[0].key,
            jr: categories[0].isjr > 0,
          });
          dispatch(setDiscipline(categories[0].discipline));
        }
      }
    }
  }, [
    dispatch,
    disciplines,
    categories,
    registerMode.discipline,
    params.discipline,
    setRegisterMode,
  ]);

  useEffect(() => {
    if (!parameters?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, parameters]);

  useEffect(() => {
    const s_add = searchParams.get("add");

    if (s_add && userInfo?.isParent) {
      let parents = [
        {
          surname: userInfo.surname,
          name: userInfo.name,
          parid: userInfo.id,
          link: "",
          email: "",
          cf: "",
          phonenr: "",
        },
      ];
      setModUser((prevUser) => {
        return { ...prevUser, parents };
      });
      setLockParents(true);
    }
  }, [searchParams, userInfo]);

  const trainingsError = () => {
    if (modUser.trainings === 0x0f) return "Troppi giorni selezionati";
    if (modUser.trainings === 0x00) return "Selezionare almeno un giorno";
    return null;
  };

  const handleChangeTraining = (event) => {
    const num = parseInt(event.target.name);
    let trainings = modUser.trainings;
    if (event.target.checked) {
      trainings = trainings | num;
    } else {
      trainings = trainings & ~num;
    }
    setModUser({ ...modUser, trainings });
  };

  const onChangeUserParam = (param, value, type = "text") => {
    var val = value;
    if (type === "checkbox") {
      val = value ? 1 : 0;
    }

    // reset group in case of change of location
    if (param === "location") {
      setModUser((prevUser) => {
        return { ...prevUser, group: "" };
      });
    }

    setModUser((prevUser) => {
      return { ...prevUser, [param]: val };
    });
  };

  const onChangeParentParam = (index, param, value) => {
    let parents = [...modUser.parents];
    parents[index] = { ...modUser.parents[index], [param]: value };

    setModUser({ ...modUser, parents });
  };

  const handleChangeDiscipline = (event) => {
    const id = event.target.value;
    const index = disciplines.findIndex((disc) => disc.id === id);

    if (index !== -1) {
      dispatch(setDiscipline(disciplines[index].id));
      const cat = categories.find(
        (c) => c.isinternal === "0" && c.discipline === disciplines[index].id
      );
      setRegisterMode({
        ...registerMode,
        discipline: disciplines[index].id,
        discName: disciplines[index].name,
        disc: disciplines[index],
        locked: false,
        jr: cat ? cat.isjr > 0 : registerMode.jr,
        category: cat ? cat.key : null,
      });
    }
  };

  const handleChangeCategory = (event) => {
    const key = event.target.value;
    const index = categories.findIndex((cat) => cat.key === key);

    if (index !== -1)
      setRegisterMode({
        ...registerMode,
        jr: categories[index].isjr > 0,
        category: key,
      });
  };

  const addParent = () => {
    const parents = [
      ...modUser.parents,
      { surname: "", name: "", link: "", email: "", cf: "", phonenr: "" },
    ];

    setModUser({ ...modUser, parents });
  };

  const removeParent = (index) => {
    const parents = modUser.parents.filter((p, idx) => idx !== index);

    setModUser({ ...modUser, parents });
  };

  async function checkExists() {
    if (!modUser.nickname) {
      setNickNameError(false);
      return false;
    }

    const exists = await checkNicknameExists(modUser.nickname);

    setNickNameError(exists);

    return exists;
  }

  const handleConfirm = async (event) => {
    const valid = event.currentTarget.form.reportValidity();
    if (!valid) return;

    if (!registerMode.locked) {
      // check user
      if (await checkExists()) return;

      // check password
      if (password !== password1 || password.length < 3) {
        setErrorPassword("Password troppo corta o non coincidenti");
        return;
      }
    }

    setRegisterMode({
      ...registerMode,
      locked: !registerMode.locked,
    });
  };

  const handleRegister = async () => {
    setLoading(true);
    setMessage("");
    setErrorMessage("");
    const reply = await registerUser(
      {
        ...modUser,
        birthdate: moment(modUser.birthdateD).format("DD/MM/YYYY"),
        category: registerMode.category,
        jr: registerMode.jr ? 1 : 0,
        email: registerMode.jr ? modUser.parents[0].email : modUser.email,
        phonenr: registerMode.jr ? modUser.parents[0].phonenr : modUser.phonenr,
        password,
      },
      registerMode.disc
    );

    setLoading(false);
    if (reply.error) {
      setErrorMessage(reply.message);
      if (reply.cferror)
        setErrorMessage("Dati non corretti per generare il codice fiscale");
    } else {
      setMessage(reply.message);
      if (userInfo?.id === undefined) {
        dispatch(signin(modUser.nickname, password, ""));
        navigate(`/confirmmail/UID${reply.id}`);
      } else {
        navigate(`/`);
      }
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Box sx={{ p: 2 }}>
        AVVISO: con la presente registrazione, si accetta che le informazioni
        inserite siano gestite da {publicName} nel rispetto delle normative
        vigenti a tutela della privacy.
      </Box>
      <Box component="form" autoComplete="off">
        <Grid container spacing={2} padding={2}>
          {!registerMode.filter && (
            <Grid item xs={6} md={4}>
              <FormControl
                fullWidth
                variant="standard"
                disabled={registerMode.locked}
              >
                <InputLabel htmlFor="disc">Sport</InputLabel>
                <Select
                  label="Sport"
                  id="disc"
                  value={registerMode.discipline}
                  onChange={handleChangeDiscipline}
                >
                  {disciplines?.map((disc) => (
                    <MenuItem key={disc.id} value={disc.id}>
                      {disc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={8} md={6}>
            <FormControl>
              <FormLabel id="type">Profilo Utente</FormLabel>
              <RadioGroup
                row
                aria-labelledby="type-radio-labels"
                name="row-radio-buttons-group"
                value={registerMode.category}
                onChange={handleChangeCategory}
              >
                {categories &&
                  categories
                    .filter(
                      (cat) =>
                        (cat.isinternal === "0" ||
                          userInfo?.mgrDiscipline?.includes(
                            registerMode.discipline
                          ) ||
                          userInfo?.isAdmin) &&
                        cat.discipline === registerMode.discipline
                    )
                    .map((cat) => (
                      <FormControlLabel
                        key={cat.key}
                        value={cat.key}
                        control={<Radio />}
                        label={cat.description}
                      />
                    ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Dati atleta iscritto</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <TextField
                id="surname"
                label="Cognome"
                type="text"
                value={modUser.surname}
                variant="standard"
                disabled={registerMode.locked}
                onChange={(e) => onChangeUserParam("surname", e.target.value)}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <TextField
                id="name"
                label="Nome"
                type="text"
                value={modUser.name}
                variant="standard"
                disabled={registerMode.locked}
                onChange={(e) => onChangeUserParam("name", e.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl>
              <FormLabel id="gender">Sesso</FormLabel>
              <RadioGroup
                row
                aria-labelledby="gender-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={modUser.gender}
                onChange={(e) => onChangeUserParam("gender", e.target.value)}
              >
                <FormControlLabel
                  value="M"
                  control={<Radio />}
                  label="Maschile"
                  disabled={registerMode.locked}
                />
                <FormControlLabel
                  value="F"
                  control={<Radio />}
                  label="Femminile"
                  disabled={registerMode.locked}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {!registerMode.jr && (
            <>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    id="email"
                    label="E-mail"
                    type="email"
                    value={modUser.email}
                    variant="standard"
                    disabled={registerMode.locked}
                    onChange={(e) => onChangeUserParam("email", e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    id="mobilenr"
                    label="Cellulare"
                    type="tel"
                    value={modUser.phonenr}
                    variant="standard"
                    disabled={registerMode.locked}
                    onChange={(e) =>
                      onChangeUserParam("phonenr", e.target.value)
                    }
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    id="homenr"
                    label="Telefono casa"
                    type="tel"
                    value={modUser.homenr}
                    variant="standard"
                    disabled={registerMode.locked}
                    onChange={(e) =>
                      onChangeUserParam("homenr", e.target.value)
                    }
                  />
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <MobileDatePicker
                inputFormat="DD/MM/YYYY"
                id="birthdate"
                label="Data di nascita"
                type="text"
                value={modUser.birthdateD}
                disabled={registerMode.locked}
                onChange={(value) => onChangeUserParam("birthdateD", value)}
                required
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
              />
            </FormControl>
          </Grid>
          <BirthPlace
            locked={registerMode.locked}
            modUser={modUser}
            onChangeUserParam={onChangeUserParam}
          />
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <TextField
                id="citizenship"
                label="Cittadinanza"
                type="text"
                value={modUser.citizenship}
                variant="standard"
                disabled={registerMode.locked}
                onChange={(e) =>
                  onChangeUserParam("citizenship", e.target.value)
                }
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <TextField
                id="city"
                label="Comune di Residenza"
                type="text"
                value={modUser.city}
                variant="standard"
                disabled={registerMode.locked}
                onChange={(e) => onChangeUserParam("city", e.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} md={2}>
            <FormControl fullWidth>
              <TextField
                id="prov"
                label="Provincia di residenza"
                type="text"
                value={modUser.prov}
                variant="standard"
                disabled={registerMode.locked}
                onChange={(e) => onChangeUserParam("prov", e.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <TextField
                id="address"
                label="Indirizzo"
                type="text"
                value={modUser.address}
                variant="standard"
                disabled={registerMode.locked}
                onChange={(e) => onChangeUserParam("address", e.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} md={2}>
            <FormControl fullWidth>
              <TextField
                id="postcode"
                label="CAP"
                type="text"
                value={modUser.postcode}
                variant="standard"
                disabled={registerMode.locked}
                onChange={(e) => onChangeUserParam("postcode", e.target.value)}
                required
              />
            </FormControl>
          </Grid>

          {registerMode.jr && (
            <>
              {modUser?.parents &&
                modUser.parents.map((parent, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Stack direction="row">
                        <Typography variant="h5">
                          Dati Genitore {index + 1}
                        </Typography>
                        {index > 0 && !lockParent && (
                          <IconButton onClick={() => removeParent(index)}>
                            <Delete />
                          </IconButton>
                        )}
                        {registerMode.jr && index === 0 && !lockParent && (
                          <IconButton
                            onClick={addParent}
                            size="small"
                            sx={{ ml: 4 }}
                          >
                            <AddCircleOutlineIcon />
                            Aggiungi genitore
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={4} key={index}>
                      <FormControl fullWidth>
                        <TextField
                          id={`psurname${index}`}
                          label="Cognome"
                          type="text"
                          value={parent.surname}
                          variant="standard"
                          disabled={registerMode.locked || lockParent}
                          onChange={(e) =>
                            onChangeParentParam(
                              index,
                              "surname",
                              e.target.value
                            )
                          }
                          required={index === 0}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          id={`pname${index}`}
                          label="Nome"
                          type="text"
                          value={parent.name}
                          variant="standard"
                          disabled={registerMode.locked || lockParent}
                          onChange={(e) =>
                            onChangeParentParam(index, "name", e.target.value)
                          }
                          required={index === 0}
                        />
                      </FormControl>
                    </Grid>
                    {!lockParent && (
                      <>
                        <Grid item xs={4}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="category">
                              Parentela
                            </InputLabel>
                            <Select
                              id={`plink${index}`}
                              label="Parentela"
                              value={parent.link}
                              disabled={registerMode.locked}
                              onChange={(e) =>
                                onChangeParentParam(
                                  index,
                                  "link",
                                  e.target.value
                                )
                              }
                            >
                              {["Padre", "Madre", "Tutore"].map((opt) => (
                                <MenuItem key={opt} value={opt}>
                                  {opt}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={4} md={3}>
                          <FormControl fullWidth>
                            <TextField
                              id={`pemail${index}`}
                              label="e-Mail"
                              type="email"
                              value={parent.email}
                              variant="standard"
                              disabled={registerMode.locked}
                              onChange={(e) =>
                                onChangeParentParam(
                                  index,
                                  "email",
                                  e.target.value
                                )
                              }
                              required={index === 0}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} md={3}>
                          <FormControl fullWidth>
                            <TextField
                              id={`pcf${index}`}
                              label="Codice Fiscale (se intestatario ricevuta)"
                              type="text"
                              value={parent.cf}
                              variant="standard"
                              disabled={registerMode.locked}
                              onChange={(e) =>
                                onChangeParentParam(index, "cf", e.target.value)
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} md={3}>
                          <FormControl fullWidth>
                            <TextField
                              id={`pphonenr${index}`}
                              label="Telefono"
                              type="text"
                              value={parent.phonenr}
                              variant="standard"
                              disabled={registerMode.locked}
                              onChange={(e) =>
                                onChangeParentParam(
                                  index,
                                  "phonenr",
                                  e.target.value
                                )
                              }
                              required={index === 0}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </React.Fragment>
                ))}
            </>
          )}

          {registerMode.discipline && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">{registerMode.discName}</Typography>
              </Grid>

              {hasTrainingDays && (
                <Grid item xs={12} md={6}>
                  <FormControl
                    required
                    error={trainingsError() != null}
                    component="fieldset"
                    variant="standard"
                    disabled={registerMode.locked}
                  >
                    <FormLabel id="trainings">Allenamenti</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(modUser.trainings & 0x01) > 0}
                            onChange={handleChangeTraining}
                            name="1"
                          />
                        }
                        label="Lunedi"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(modUser.trainings & 0x02) > 0}
                            onChange={handleChangeTraining}
                            name="2"
                          />
                        }
                        label="Martedi"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(modUser.trainings & 0x04) > 0}
                            onChange={handleChangeTraining}
                            name="4"
                          />
                        }
                        label="Mercoledi"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(modUser.trainings & 0x08) > 0}
                            onChange={handleChangeTraining}
                            name="8"
                          />
                        }
                        label="Venerdi"
                      />
                      <FormHelperText>{trainingsError()}</FormHelperText>
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}

              {locations?.length > 0 && (
                <>
                  <Grid item xs={6} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="location" variant="standard">
                        Campo sportivo / settore
                      </InputLabel>
                      <Select
                        id="location"
                        label="Campo Sportivo / settore"
                        value={modUser.location}
                        disabled={registerMode.locked}
                        onChange={(e) =>
                          onChangeUserParam("location", e.target.value)
                        }
                      >
                        {locations?.map((loc) => (
                          <MenuItem key={loc.id} value={loc.id}>
                            {loc.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {groupNames &&
                    Object.entries(groupNames)?.filter(
                      ([key, value]) => value.location === modUser.location
                    ).length > 0 && (
                      <Grid item xs={6} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="group" variant="standard">
                            Gruppo
                          </InputLabel>
                          <Select
                            id="group"
                            label="Gruppo"
                            value={modUser.group}
                            disabled={registerMode.locked}
                            onChange={(e) =>
                              onChangeUserParam("group", e.target.value)
                            }
                          >
                            {Object.entries(groupNames)
                              ?.filter(
                                ([key, value]) =>
                                  value.location === modUser.location
                              )
                              .map(([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                </>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="h5">Crea le tue credenziali</Typography>
          </Grid>
          <Grid item xs={4} md={3}>
            <FormControl fullWidth>
              <TextField
                id="nickname"
                label="Username"
                value={modUser.nickname}
                variant="standard"
                disabled={registerMode.locked}
                error={nickNameError}
                helperText={nickNameError ? "Username non disponibile" : ""}
                onChange={(e) => onChangeUserParam("nickname", e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => checkExists()}>
                        <Person />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} md={3}>
            <FormControl fullWidth>
              <TextField
                id="password"
                label="Password"
                type="password"
                value={password}
                disabled={registerMode.locked}
                error={errorPassword !== ""}
                helperText={errorPassword}
                variant="standard"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} md={3}>
            <FormControl fullWidth>
              <TextField
                id="password1"
                label="Ripeti password"
                type="password"
                value={password1}
                disabled={registerMode.locked}
                variant="standard"
                onChange={(e) => setPassword1(e.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              disabled={registerMode.category === null}
            >
              {registerMode.locked ? "Indietro" : "Conferma"}
            </Button>
          </Grid>
          {registerMode.locked && (
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="success"
                disabled={registerMode.category === null}
                onClick={handleRegister}
              >
                Salva dati
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
